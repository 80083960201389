<template>
  <div>
        <!-- go back to the custom page and upload to index -->
      <van-nav-bar
        title="发布新路线"
        @click-right="onClickRight"
        :disable="isLock"
      >
        <template #right>
          <van-icon name="share" size="23"/>
        </template>
      </van-nav-bar>
    <!-- the content of upload page, user can write and share route -->
    <div class="upload-content">
        <textarea v-model="content" autofocus placeholder="快和岩友分享路线吧 . . ." class="content"></textarea>
        
        <div class="add-picture">
            <img :src="signSrc" alt="">  
        </div>
        <!-- this is the tags that climbers can choose the difficulty and location -->
        
        <div class="tag">

          <van-tag plain type="primary" @click="showArea = true" size="large">#{{value}}</van-tag>
          <van-popup v-model="showArea" position="bottom">
            <van-picker
              show-toolbar
              :columns="columns"
              @confirm="onConfirm"
              @cancel="showArea = false"
            />
          </van-popup>


          <van-tag plain type="primary" @click="showPicker = true" size="large">#{{location}}</van-tag>
          <van-popup v-model="showPicker" position="bottom">
            <van-picker
              show-toolbar
              :columns="columnsLoc"
              @confirm="onConfirm1"
              @cancel="showPicker = false"
            />
          </van-popup>

        </div>

        
    </div>

  
    <van-overlay :show="isLock" @click="show = false">
      <div class="wrapper" @click.stop>
        <van-loading v-show="isLock" type="spinner" color="white" text-color="white" size="45px" vertical style="position:absolute; margin: 0; top: 240px; left: 0; right: 0; margin: auto; ">上传中...</van-loading>
      </div>
    </van-overlay>
    
  </div>

</template>

<script>
import { Toast } from 'vant'
import Vue from 'vue';

export default {
  data() {
    return {
      content:"",
      showArea: false,
      showPicker:false,
      signSrc:sessionStorage.getItem("pic2"),
      columns: ['V0-V1', 'V1-V2', 'V2-V3', 'V3-V4', 'V4-V5','V5-V6','V6-V7','V7-V8','V8-V9','V9-V10'],
      columnsLoc: ['西交利物浦大学体育馆','刘常忠攀岩馆(奥体中心店)', '刘常忠攀岩馆(独墅湖店)','其他抱石馆'],
      value: '难度',
      location: '位置',
      isLock: false,
      
    }
  },
  methods: {

    onClickRight(){

      if(this.value!='难度'&&this.location!='位置'){
        this.isLock = true
        var blob = this.dataURItoBlob(this.signSrc);
        const that = this
        const forms = new FormData();
        forms.append("image", blob);
        // console.log("==========",that.signSrc)
        that.$axios.post('/user/editAvatar',forms,{
          headers: {
            "content-type": "multipart/form-data"
          }
        }).then(
            response=>{
                // console.log("ddwaa",response)
                
                this.editInfo(response.data.data);
                
            }
        )
      }else{
        Toast('请填选完路线难度和位置再发布哦~')
      }

    },

    editInfo(pic){
      const _this =this
      
      _this.$axios.post("/blog/edit",{
        user_id: _this.$store.getters.getUser.id,
        username: _this.$store.getters.getUser.username,
        avatar: _this.$store.getters.getUser.avatar,
        nickname: _this.$store.getters.getUser.nickname,
        difficulty: _this.value,
        location: _this.location,
        pic: pic,
        content:_this.content,
      },{
          headers: {"Authorization":localStorage.getItem("token")}
        }).then(
          res =>{
              // console.log(res)
              Toast("上传成功(❁´◡`❁)")
              this.isLock =false
              _this.$router.replace("/Main")
          }
      )
    },

    dataURItoBlob (base64Data) {
      var byteString;
      if (base64Data.split(',')[0].indexOf('base64') >= 0)
          byteString = atob(base64Data.split(',')[1]);
      else
          byteString = unescape(base64Data.split(',')[1]);
      var mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0];
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], {type: mimeString});
    },


    onConfirm1(value) {
      this.location = value
      this.showPicker = false
    },
    onConfirm(value) {
      this.value = value
      this.showArea = false
    },

  },
}

// Vue.directive("image", {
//     inserted: function(el, binding){
//          var color = Math.floor(Math,random()*1000000)
//          el.style.backgroundColor = "#" + color
//          var img = new Image()
//          img.src = binding.vaule
//          img.onload = function(){ 
//            el.style.backgroundImage =  "url(" + binding.vaule + ")"
//          }
//      }
//  })

</script>

<style scoped>


.upload-content{
    position: relative;
    width: 100%;
    margin-top: 10px;
    background-color: white;
}

.upload-content textarea{
    position: relative;
    width: 95%;
    height: 120px;
    margin-top: 10px;
    margin-left: 10px;
    overflow: hidden;
    border: solid 0px;
    outline:none;
    resize: none;

}

input:-moz-placeholder,
textarea:-moz-placeholder { 
    color: #b5b5b5;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder { 
    color: #b5b5b5;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder { 
    color: #b5b5b5;
} 

.add-picture {
    position: relative;
    margin-left: 10px;
    width: 200px;
    height: 200px;
    background-color: #ffffff;
    /* overflow: hidden; */
    border-radius: 10px;
}

.add-picture img {
    width: 100%;
    border-radius: 10px;
}

.tag {
  margin-top: 65px;
    display: flex;
    width: 100%;
    padding: 10px;
}

.tag span {
  /* margin: 0 auto; */
  margin: 5px;
}


</style>